import React from 'react';

import Layout from '../components/common/layout';
import Footer from '../components/common/Footer';
import Header from '../components/common/Header';


const Privacy = () => (
  <Layout>
    <Header />

    <div id="wrapper">
      <section id="main" className="wrapper">
        <div className="inner">
        	<h1 className="major">Privacy</h1>
        	<h2>Privacy Policy</h2>
        	<h3>1. Our Commitment</h3>
        	<p>
        		The protection of privacy and personal data is a fundamental commitment of Sintra Climbing Tours. (hereinafter referred to as "Sintra Climbing Tours"), towards: <br/>
        		- Customers and passengers of their products and services (hereinafter referred to as "Customers"); <br/>
        		- Its employees (hereinafter referred to as "Employees"); <br/>
        		- Its job applicants and candidates (hereinafter referred to as "Applicants").<br/>
				Sintra Climbing Tours advises you to fully read this document and also Sintra Climbing Tours’s General Conditions of Service, which are available at <a href="/terms">http://www.sintraclimbingtours.com/terms</a>
        	</p>
        	<h3>2. Data Manager and Data Protection Manager</h3>
        	<p>
        		The responsibility for collecting and processing your personal data is Sintra Climbing Tours. Sintra Climbing Tours also has a Data Protection Officer (DPO), which:<br/>
        		- Monitors the compliance of data processing with applicable standards; <br/>
        		- It is a point of contact with the Customer, Employee and Applicant to clarify matters regarding the treatment of their data by Sintra Climbing Tours; <br/>
        		- Cooperates with the supervisory authority; <br/>
        		- Provides information and advises the controller or subcontractor on their obligations regarding privacy and data protection.
        	</p>
        	<h3>3. Personal Data, Personal Data Holders and Personal Data Categories</h3>
        	<p>What is personal data?</p>
        	<p>Personal Data is any information of any nature and on any support (e.g. paper, e-mail, sound or image, etc.) relating to an identified or identifiable individual.</p>
        	<p>Any person who can be identified directly or indirectly is considered identifiable, e.g. by name, identification number, a location data, an electronic identifier or other elements that enable the identification of that individual.</p>
        	<p>Who are the owners of personal data?</p>
        	<p>
        		The Customer and the Passenger, individual, to whom the data relate and who has used the services (or products) of Sintra Climbing Tours and its associated brands. The Customer will be the person who accepts the service contract agreement with Sintra Climbing Tours and the Passenger is the person who uses the services (or products) of Sintra Climbing Tours or its associated brands, but may not correspond to the Customer. For example:<br/>
        		- A Customer may acquire for itself exclusively one or more experiences and/or travels of Sintra Climbing Tours;<br/>
        		- A Customer may acquire one or more experiences and/or travels for itself and for other persons (considered as Passengers), each of which (Customer and Passenger) will also benefit from Sintra Climbing Tours services and/or products.<br/>
        	</p>
        	<p>
        		The Employee and the Applicant, individual, to whom the data relate and who celebrates or intends to celebrate an employment contract (or professional internship) with Sintra Climbing Tours.<br/>
        		In this regard, Sintra Climbing Tours informs that it also protects personal data and respects the rights of Customers and Passengers, Employees and Applicants. <br/>
        		What categories of personal data do we treat?<br/>
        		Identification data and examples (not exhaustive):<br/>
        		Identification and contacts: Civil or tax identification number, payment data, address, telephone contact or email address.<br/>
        		Other identification data: Date of birth or gender.<br/>
        		Service and/or product: Typology and characteristics of services and/or products purchased.<br/>
        		Profile and interests: Interests in services and/or products of Sintra Climbing Tours and its associated brands, social networks or other preferences and interests.<br/>
        		Location: Customer’s origin geographic reference and/or geographical references of the experiences and/or travels contracted to Sintra Climbing Tours.<br/>
        	</p>
        	<h3>4. Fundament, Purposes and Duration of the Treatment of Personal Data</h3>
        	<p>4.1. On what grounds can Sintra Climbing Tours handle your personal data?</p>
        	<p>4.1.1. Consent: When we have your express consent - in writing, orally or through the validation of an option - and prior and if such consent is free, informed, specific and unambiguous. Examples are the Customer's consent for Sintra Climbing Tours to analyze the use of the services and consumer profile and to make suggestions or send marketing messages, to use their identification data or the use of services to send marketing messages, his consent so that we can use the calls recording that he (or she) makes to our telephone numbers in order to improve the quality of service; or </p>
        	<p>4.1.2. Contract execution and pre-contractual procedures: When the processing of personal data is necessary for the execution and management of the service agreement(s) celebrated with Sintra Climbing Tours, for example, for the preparation of service(s) proposal, contact management, information and requests, billing, collection and payment management; or</p>
        	<p>4.1.3. Compliance with legal obligations: When the processing of personal data is necessary to fulfill a legal obligation to which Sintra Climbing Tours is subject, such as the communication of identification data to police, judicial, fiscal or regulatory entities or location data to ensure emergency services; or </p>
        	<p>4.1.4. Legitimate interest: When the processing of personal data corresponds to a legitimate interest of Sintra Climbing Tours or third parties, for example, data processing for quality of service improvement, fraud detection and revenue protection and when our reasons for this use should prevail over your data protection rights. </p>
        	<p>4.1.5. Consent by minors: In the case of minors personal data processing, that may be subject to prior consent, Sintra Climbing Tours will require authorization by the holders of parental responsibilities, namely for the purpose of providing services, by written consent on paper or by electronic means.</p>
        	<p>4.2. For what purposes and for how long does Sintra Climbing Tours treat your personal data? <br/>
	        	Your personal data are processed by Sintra Climbing Tours only for the period of time necessary to achieve the defined purpose or according to what is applicable, until you exercise your right of opposition, right to be forgotten or withdraw consent. After the respective maintenance period has elapsed, Sintra Climbing Tours will eliminate or anonymize the data when they are not to be kept for a different purpose that can be maintained.<br/>
	        	 Purpose and examples of purposes (non-exhaustive): </p>
        	<p>4.2.1. Marketing and sales: Marketing or sale of new services and/or products; analysis of consumption profiles; adaptation and development of new services and/or products.</p>
        	<p>4.2.2. Customer management and service delivery: Management of contacts, information or requests; management of complaints; billing and payment management; recording of calls for proof of commercial transaction and communications under the contractual relationship. </p>
        	<p>4.2.3. Accounting, tax and administrative management: Accounting and billing; tax information, including sending information to the taxing authority.</p>
        	<p>4.2.4. Litigation management: Judicial and extrajudicial collection; management of other conflicts.</p>
        	<p>4.2.5. Fraud detection, revenue protection and auditing: Detection of fraud and illicit practices; protection and revenue control; audit and internal investigation.</p>
        	<p>4.2.6. Network and system management: Support and improvement of the networks and applications that support the service; monitoring, improvement and service support.</p>
        	<p>4.2.7. Compliance with legal obligations: Investigation, detection and prosecution of serious crimes; response to judicial, regulatory and supervisory entities.</p>
        	<p>4.2.8. Information security control: Access management; management of backups; management of security incidents.</p>
        	<p>4.2.9. Physical security control: Video surveillance in facilities (when applicable).</p>
        	<p>4.3. What are the deadlines for processing and keeping personal data?</p>
        	<p>Sintra Climbing Tours treats and stores your personal data according to the purposes for which they are processed.</p>
        	<p>There are cases where the law requires the data to be processed and kept for a minimum period of time, namely for 1 year, the location data for the purpose of investigation, detection and prosecution of serious crimes or for 10 years the data necessary for information to the tax authority for accounting or tax purposes. </p>
        	<p>But, whenever there is no specific legal obligation, then the data will be processed only for the period necessary to fulfill the purposes that led to their collection and preservation and always in accordance with the law, guidelines and decisions of the CNPD. Therefore: </p>
        	<p>Sintra Climbing Tours will process and maintain personal data for the period in which it has a contractual relationship with the Customer and the Employer. In the specific case of the Applicant, Sintra Climbing Tours will process and maintain his personal data for a period of 1 year, time after which point 4.2. will be applied. </p>
        	<p>In the context of calls recording, when applicable, and under the terms of the normative authorization, Sintra Climbing Tours will keep the recordings for evidence of the commercial transaction and communications within the contractual relationship for the duration of the contract agreed between the parties plus the deadline prescription and expiry which is 6 months. </p>
        	<p>Sintra Climbing Tours may also record and retain calls for the purpose of monitoring the quality of service and based on Customer's consent, but for this purpose, calls will be retained for a maximum of 30 days.</p>
        	<p>Regarding the video surveillance of its installations, Sintra Climbing Tours will only keep the recordings of images and their personal data for a maximum period of 30 days.</p>
        	<p>Sintra Climbing Tours may maintain other personal data for periods longer than the duration of the contractual relationship, whether based on Customer's, Employee's and Applicant's consent, or to ensure rights or duties related to the contract, or even because it has legitimate interests but for the period strictly necessary for the fulfillment of the respective purposes and in accordance with the guidelines and decisions of CNPD. </p>
        	<p>Examples are, in the case of the Customer, the contact for marketing and sales purposes, the preservation of data in the context of complaints, exercise of rights or legal proceedings, for the period in which they are pending.</p>
        	<h3>5. THE FORM AND MOMENT OF COLLECTING PERSONAL DATA</h3>
        	<p>5.1. When and how do we collect your personal data?<br/>
        		 We collect personal data by your consent when you purchase or use services (or products) from Sintra Climbing Tours and also when you participate in market researches. Applicants data are collected when they submits spontaneous applications or responds to job advertisements (and/or professional internships). <br/>
        		The collection may be done orally, in writing or through the website of Sintra Climbing Tours. <br/>
        		Sintra Climbing Tours may also access, collect or confirm personal data on public administration sites and private entities, in particular to confirm the accuracy of your identification and contact information.
        	</p>
        	<h3>6. Rights of the Personal Data Holder</h3>
        	<p>6.1. What are your rights?</p>
        	<p>6.1.1. Right of access: Right to obtain confirmation of which of your personal data are treated and information about them, such as, what are the purposes of the treatment, what are the conservation periods, among others. Right to see/hear or obtain a copy, for example of invoices, written agreements or calls in which you are an intervenient and which are recorded.</p>
        	<p>6.1.2. Right of rectification: Right to request the rectification of your personal data that are inaccurate or to request incomplete personal data, such as the address, tax number, e-mail, telephone or other contacts.</p>
        	<p>6.1.3. Right to erase data or "Right to be forgotten": Right to erase your personal data, provided that there are no valid grounds for its retention, such as, for example, cases where Sintra Climbing Tours has to keep data to fulfill a legal obligation of preservation for investigation, detection and prosecution of crimes or because a legal process is under way. </p>
        	<p>6.1.4. Right to portability: Right to receive the data you have provided us in digital format of current use and automatic reading or to request the direct transmission of your data to another entity that becomes the new responsible for your personal data. However, the exercise of the right to data portability should not prejudice the rights and freedoms of third parties. For example, if the set of data transferred following the request made by the data subject contains personal data relating to other persons, the new data controller should only handle such data if there is an adequate legal basis for such processing. </p>
        	<p>6.1.5. Right to withdraw consent or right of opposition: Right to object or withdraw consent at any time to a data processing, such as in the case of data processing for marketing purposes, provided that there are no legitimate interests that prevail over your interests, rights and freedoms, such as the defense of a right in a judicial process. </p>
        	<p>6.1.6. Right of limitation: Right to request the limitation of the processing of your personal data in the form of or suspension of processing or limitation of the scope of treatment to certain categories of data or purposes of treatment. </p>
        	<p>6.1.7. Automated profile and decisions: Sintra Climbing Tours can define the clients profile based on their preferences, for example, on their personal interests, use of service and/or product, location, etc., in particular to provide services, increase quality and experience of the services and/or products, adjust marketing communications etc., provided that such treatment is necessary for the celebration or execution of the contract between the holder and Sintra Climbing Tours or be based on the consent of the holder. <br/>
        		Where the processing of personal data, including processing for profiling, is exclusively automatic (i.e. without human intervention) and may have a legal effect or significantly affect it, it shall have the right not to be subject to any decision which is based on such automatic treatment, except as provided by law and shall have the right to Sintra Climbing Tours take appropriate measures to safeguard its rights and freedoms and legitimate interests, including the right to have human intervention in the decision making process by Sintra Climbing Tours, the right to express your point of view or to contest the decision taken based on the automated processing of personal data. </p>
    		<p>6.1.8. Right to complain: Right to submit a complaint to the supervisory authority, CNPD, in addition to Sintra Climbing Tours.</p>
    		<p>6.1.9. Right to reject confidential calls: Right to reject incoming calls when the calling party is anonymous.</p>
    		<p>6.2. How can you exercise your rights? <br/>
    			The exercise of rights is free, unless it is a manifestly unfounded or excessive request, in which case a reasonable fee may be levied in the light of costs. The information shall be provided in writing but may be given orally if requested. In this case, Sintra Climbing Tours must verify your identity by means other than oral. <br/>
    			The response to requests should be provided within a maximum of 30 days, unless it is a particularly complex request. <br/>
    			Exercise them through the following contacts: <br/>
    			By letter: Rua vigia 269, 2775-298 Parede, Portugal <br/>
    			By e-mail: privacy.sintraclimbingtours@gmail.com or  <br/>
    			By telephone: +351 969 482 823 or through  CNDP, at https://www.cnpd.pt/index.asp
			</p>
			<h3>7. Transmission of Personal Data</h3>
			<p>7.1. Under what circumstances is your personal data communicated to other entities, subcontractors or third parties?<br/>
				Your data can be transmitted to subcontractors for them to handle in the name and on behalf of Sintra Climbing Tours. In this case, Sintra Climbing Tours shall take the necessary contractual measures to ensure that subcontractors respect and protect the holder's personal data. Data may also be transmitted to third parties - entities other than Sintra Climbing Tours or subcontractors - such as associated companies of Sintra Climbing Tours, companies with which Sintra Climbing Tours develops partnerships, if the holder has consented - or entities to whom they have to be reported under the law, such as the tax authority. 
			</p>
			<p>7.2. Under what circumstances does Sintra Climbing Tours transfer its data to a third country? <br/>
				Sintra Climbing Tours may have to transfer your personal data to a third country outside the European Union and that is not included in the list of countries that the EU has already considered to meet adequate levels of personal data protection. In such cases, Sintra Climbing Tours will ensure that data transfers are carried out in strict compliance with applicable legal standards.<br/>
				Sintra Climbing Tours AND THIRD PARTY RESPONSIBILITY FOR SERVICES AND WEBSITES <br/>
				About the use and processing of personal data from the website of Sintra Climbing Tours, be sure to check the rules about the use of cookies on the website.<br/>
				 Sintra Climbing Tours's websites, apps or social networks may contain links to other third party websites, apps or services and/or products that are unrelated to Sintra Climbing Tours or are not covered by this Privacy Policy. <br/>
				The collection or processing of personal data requested by these third parties is their sole responsibility and Sintra Climbing Tours cannot be held liable under any circumstances for the content, accuracy, veracity or legitimacy of these websites or for the misuse of data collected or processed through them. <br/>
				We alert Customers, Employees and Applicants of Sintra Climbing Tours to this fact and to the need to read and accept the rules regarding the processing of personal data defined by them before using these websites, services and/or third party products or apps.
			</p>
			<h3>9. Procedural Measures and Safety Techniques</h3>
			<p>9.1. How does Sintra Climbing Tours protect your personal information?<br/>
				Sintra Climbing Tours has implemented the appropriate logical, physical, organizational and security measures necessary and sufficient to protect your personal data from destruction, loss, alteration, dissemination, unauthorized access or any other form of accidental or illicit processing. Sintra Climbing Tours has implemented: <br/>
				a) Logical security requirements and measures such as the use of firewalls and intrusion detection systems in their systems, the existence of a rigorous policy on access to systems and information and the recording of actions by Sintra Climbing Tours team on personal data of Customers, Employees and/or Applicants (logging); <br/>
				b) Physical security measures, among which a strict control of access to the physical facilities of Sintra Climbing Tours, by employees, partners and visitors, as well as a very limited and permanently monitored access to the essential technological infrastructure of Sintra Climbing Tours; <br/>
				c) Means of data protection from design ("privacy by design") using technical means such as mask, encryption, pseudonymization and anonymization of personal data, as well as a set of privacy-friendly preventive measures ("privacy by default"); <br/>
				d) Mechanisms of scrutiny, audit and control to ensure compliance with security and privacy policies;<br/>
				e) An information and training program for all the Employees of Sintra Climbing Tours.
			</p>
        </div>
      </section>
    </div>
    <Footer />
  </Layout>
);

export default Privacy;
